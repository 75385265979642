import React, { useState, useEffect, useRef } from 'react'
import { Terminal, Sun, Moon } from 'lucide-react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { ocean, gruvboxLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'

// Système de fichiers initial
const initialFileSystem = {
    '/': {
        type: 'directory',
        permissions: 'drwxr-xr-x',
        owner: 'root',
        group: 'root',
        contents: {
            bin: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    bash: { type: 'file', permissions: '-rwxr-xr-x', owner: 'root', group: 'root', contents: '' }, // Shell
                    ls: { type: 'file', permissions: '-rwxr-xr-x', owner: 'root', group: 'root', contents: '' }, // Commande ls
                    cp: { type: 'file', permissions: '-rwxr-xr-x', owner: 'root', group: 'root', contents: '' }, // Commande cp
                },
            },
            dev: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    null: { type: 'file', permissions: 'crw-r--r--', owner: 'root', group: 'root', contents: '' }, // Fichier spécial null
                    tty: { type: 'file', permissions: 'crw--w----', owner: 'root', group: 'root', contents: '' }, // Terminal
                },
            },
            etc: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    passwd: {
                        type: 'file',
                        permissions: '-rw-r--r--',
                        owner: 'root',
                        group: 'root',
                        contents: 'root:x:0:0:root:/root:/bin/bash',
                    }, // Utilisateurs
                    fstab: {
                        type: 'file',
                        permissions: '-rw-r--r--',
                        owner: 'root',
                        group: 'root',
                        contents: '/dev/sda1 / ext4 defaults 0 1',
                    }, // Système de fichiers
                    hosts: {
                        type: 'file',
                        permissions: '-rw-r--r--',
                        owner: 'root',
                        group: 'root',
                        contents: '127.0.0.1 localhost',
                    }, // Résolution des noms
                },
            },
            home: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    user: {
                        type: 'directory',
                        permissions: 'drwxr-xr-x',
                        owner: 'user',
                        group: 'user',
                        contents: {
                            documents: {
                                type: 'directory',
                                permissions: 'drwxr-xr-x',
                                owner: 'user',
                                group: 'user',
                                contents: {
                                    'resume.pdf': {
                                        type: 'file',
                                        permissions: '-rw-r--r--',
                                        owner: 'user',
                                        group: 'user',
                                        contents: '',
                                    }, // CV
                                },
                            },
                            pictures: {
                                type: 'directory',
                                permissions: 'drwxr-xr-x',
                                owner: 'user',
                                group: 'user',
                                contents: {
                                    vacation: {
                                        type: 'directory',
                                        permissions: 'drwxr-xr-x',
                                        owner: 'user',
                                        group: 'user',
                                        contents: {},
                                    }, // Dossier pour les photos de vacances
                                    'photo1.jpg': {
                                        type: 'file',
                                        permissions: '-rw-r--r--',
                                        owner: 'user',
                                        group: 'user',
                                        contents: '',
                                    }, // Photo
                                },
                            },
                            'hello.txt': {
                                type: 'file',
                                permissions: '-rw-r--r--',
                                owner: 'user',
                                group: 'user',
                                contents: 'Hello, world!',
                            },
                        },
                    },
                },
            },
            lib: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    'libc.so': { type: 'file', permissions: '-rw-r--r--', owner: 'root', group: 'root', contents: '' }, // Librairie C standard
                },
            },
            mnt: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    usb: { type: 'directory', permissions: 'drwxr-xr-x', owner: 'root', group: 'root', contents: {} }, // Point de montage USB
                },
            },
            proc: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    '1': { type: 'directory', permissions: 'dr-xr-xr-x', owner: 'root', group: 'root', contents: {} }, // Processus en cours
                },
            },
            root: {
                type: 'directory',
                permissions: 'drwx------',
                owner: 'root',
                group: 'root',
                contents: {
                    '.bashrc': { type: 'file', permissions: '-rw-r--r--', owner: 'root', group: 'root', contents: '' }, // Fichier de configuration de bash
                },
            },
            run: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    systemd: {
                        type: 'directory',
                        permissions: 'drwxr-xr-x',
                        owner: 'root',
                        group: 'root',
                        contents: {},
                    }, // État du système
                },
            },
            sys: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    kernel: {
                        type: 'directory',
                        permissions: 'drwxr-xr-x',
                        owner: 'root',
                        group: 'root',
                        contents: {},
                    }, // Informations sur le noyau
                },
            },
            usr: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    bin: { type: 'directory', permissions: 'drwxr-xr-x', owner: 'root', group: 'root', contents: {} }, // Utilitaires
                    share: { type: 'directory', permissions: 'drwxr-xr-x', owner: 'root', group: 'root', contents: {} }, // Données partagées
                },
            },
            var: {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: 'root',
                group: 'root',
                contents: {
                    log: { type: 'directory', permissions: 'drwxr-xr-x', owner: 'root', group: 'root', contents: {} }, // Journaux système
                    run: { type: 'directory', permissions: 'drwxr-xr-x', owner: 'root', group: 'root', contents: {} }, // Données d'exécution
                },
            },
        },
    },
}

const TerminalApp = () => {
    const [theme, setTheme] = useState('light')
    const [input, setInput] = useState('')
    const [output, setOutput] = useState([
        'Welcome to the Linux Terminal Emulator. Type "help" to display the list of commands.',
    ])
    const [currentDirectory, setCurrentDirectory] = useState('/home/user')
    const [fileSystem, setFileSystem] = useState(initialFileSystem)
    const [history, setHistory] = useState([])
    const [historyIndex, setHistoryIndex] = useState(-1)
    const [currentUser, setCurrentUser] = useState('user')

    const terminalRef = useRef(null)
    const inputRef = useRef(null)

    useEffect(() => {
        document.body.className = theme
    }, [theme])

    useEffect(() => {
        if (terminalRef.current) {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight
        }
    }, [output])

    // Fonction pour accéder à un répertoire donné
    const getDir = (path) => {
        const absolutePath = resolvePath(path)
        const parts = absolutePath.split('/').filter(Boolean)
        let current = fileSystem['/']
        for (const part of parts) {
            if (!current || current.type !== 'directory' || !current.contents || !current.contents[part]) {
                return null
            }
            current = current.contents[part]
        }
        return current
    }

    const updateFileSystem = (path, content) => {
        const newFileSystem = JSON.parse(JSON.stringify(fileSystem))
        const parts = path.split('/').filter(Boolean)
        let current = newFileSystem['/']

        for (let i = 0; i < parts.length - 1; i++) {
            if (!current.contents[parts[i]]) {
                current.contents[parts[i]] = {
                    type: 'directory',
                    permissions: 'drwxr-xr-x',
                    owner: currentUser,
                    group: currentUser,
                    contents: {},
                }
            }
            current = current.contents[parts[i]]
        }

        current.contents[parts[parts.length - 1]] = content
        setFileSystem(newFileSystem)
    }

    // Fonction pour résoudre les chemins
    const resolvePath = (path) => {
        if (path.startsWith('/')) return path // Si le chemin est absolu, le retourner tel quel

        if (!currentDirectory) return '/' // Si currentDirectory est vide, retourner la racine

        const currentParts = currentDirectory.split('/').filter(Boolean) // Sépare le répertoire courant
        const newParts = path.split('/').filter(Boolean) // Sépare le nouveau chemin

        for (const part of newParts) {
            if (part === '..') {
                // Remonter d'un niveau dans le répertoire
                if (currentParts.length > 0) currentParts.pop()
            } else if (part !== '.') {
                currentParts.push(part) // Ajoute le nouveau segment au chemin
            }
        }

        return '/' + currentParts.join('/') // Reconstitue le chemin final
    }

    // Vérification des permissions
    const hasPermission = (file, permission) => {
        if (!file) return false
        if (currentUser === 'root') return true
        const relevantPerms = file.owner === currentUser ? file.permissions.slice(1, 4) : file.permissions.slice(7, 10)
        return relevantPerms[permission === 'r' ? 0 : permission === 'w' ? 1 : 2] === permission
    }

    const executeSingleCommand = (command) => {
        const [cmd, ...args] = command.split(' ')

        if (commands[cmd]) {
            const result = commands[cmd](args)

            // Vérifiez si le résultat est un objet avec les propriétés output et success
            if (typeof result === 'object' && result !== null && 'output' in result && 'success' in result) {
                return result
            } else if (typeof result === 'string') {
                // Pour la compatibilité avec les anciennes commandes qui retournent juste une chaîne
                return { output: result, success: !result.includes('error') && !result.includes('cannot') }
            } else {
                // Pour les commandes qui ne retournent rien (comme avant pour cd)
                return { output: '', success: true }
            }
        } else {
            return { output: `${cmd}: command not found`, success: false }
        }
    }

    const handleCommand = (input) => {
        const result = executeSingleCommand(input)
        if (input.trim() === 'clear') {
            setOutput(['Welcome to the Linux Terminal Emulator. Type "help" to display the list of commands.'])
        } else {
            setOutput((prevOutput) => [...prevOutput, `${currentDirectory} $ ${input}`, result.output].filter(Boolean))
        }
        setInput('')
        setHistory((prevHistory) => [...prevHistory, input])
        setHistoryIndex(-1)
    }

    // Commandes du terminal
    const commands = {
        help: () => {
            return `
Available commands:
- cd [directory]: Change the current directory.
  Usage: cd [path]

- pwd: Print the current working directory.
  Usage: pwd

- ls [options] [directory]: List directory contents.
  Options:
    -a: Include hidden files (starting with .)
    -l: Use long listing format
    -h: Use human-readable file sizes
    -F: Append indicator (one of */=>@|) to entries
  Usage: ls [options] [path]

- echo [options] [text]: Display a line of text.
  Options:
    -n: Do not output the trailing newline
    -e: Enable interpretation of backslash escapes
  Usage: echo [options] [text]
  Redirection: echo [options] [text] > file.txt (overwrite)
               echo [options] [text] >> file.txt (append)

- mkdir [directory]: Create a new directory.
  Usage: mkdir [directory_name]

- cat [file]: Display the contents of a file.
  Usage: cat [file_name]

- grep [pattern] [file]: Search for a pattern in a file.
  Usage: grep [pattern] [file_name]

- ps [options]: Display information about running processes.
  Options:
    -aux: Show all processes
  Usage: ps [options]

- mv [source] [destination]: Move/rename files and directories.
  Usage: mv [source_path] [destination_path]

- cp [options] [source] [destination]: Copy files and directories.
  Options:
    -a: Archive mode (preserve attributes)
  Usage: cp [options] [source_path] [destination_path]

- rm [options] [file/directory]: Remove files or directories.
  Options:
    -r, -R: Remove directories and their contents recursively
    -f: Force removal without prompting, ignore nonexistent files
  Usage: rm [options] [file_name]
         rm -rf [directory_name]

- clear: Clear the terminal screen.
  Usage: clear

- theme: Switch between light and dark themes.
  Usage: theme

For more information on a specific command, type 'man [command]'.
    `
        },
        man: (args) => {
            if (args.length === 0) {
                // Afficher l'aide générale (le contenu actuel de la fonction help)
            } else {
                const command = args[0]
                switch (command) {
                    case 'ls':
                        return `
ls: list directory contents
Usage: ls [OPTIONS] [FILE]

Options:
  -a        do not ignore entries starting with .
  -l        use a long listing format
  -h        with -l, print sizes in human readable format
  -F        append indicator (one of */=>@|) to entries

Examples:
  ls        List files in the current directory
  ls -l     List files in long format
  ls -la    List all files, including hidden ones, in long format
  ls /etc   List files in the /etc directory
                `
                    case 'echo':
                        return `
echo: display a line of text
Usage: echo [OPTIONS] [STRING]

Options:
  -n        do not output the trailing newline
  -e        enable interpretation of backslash escapes

Backslash escapes:
  \\n        new line
  \\t        horizontal tab
  \\r        carriage return

Examples:
  echo Hello, World!          Output "Hello, World!"
  echo -n No newline          Output "No newline" without a trailing newline
  echo -e "Line 1\\nLine 2"    Output two lines
                `
                    // Ajoutez d'autres cas pour chaque commande
                    default:
                        return `No specific help available for '${command}'. Use 'help' for a list of all commands.`
                }
            }
        },
        cd: (args) => {
            if (args.length === 0) {
                setCurrentDirectory('/home/' + currentUser)
                return { output: '', success: true }
            }
            const newPath = resolvePath(args[0])
            const dir = getDir(newPath)
            if (!dir) {
                return { output: `cd: no such file or directory: ${args[0]}`, success: false }
            }
            if (dir.type !== 'directory') {
                return { output: `cd: not a directory: ${args[0]}`, success: false }
            }
            if (!hasPermission(dir, 'x')) {
                return { output: `cd: permission denied: ${args[0]}`, success: false }
            }
            setCurrentDirectory(newPath)
            return { output: '', success: true }
        },
        ls: (args) => {
            const options = args.filter((arg) => arg.startsWith('-')).join('')
            const showAll = options.includes('a')
            const longFormat = options.includes('l')
            const humanReadable = options.includes('h')
            const showIndicators = options.includes('F')

            const path = args.filter((arg) => !arg.startsWith('-')).pop() || currentDirectory
            const dir = getDir(path)

            if (!dir) {
                return `ls: cannot access '${path}': No such file or directory`
            }

            if (dir.type !== 'directory') {
                return `ls: cannot open '${path}': Not a directory`
            }

            if (!hasPermission(dir, 'r')) {
                return `ls: cannot open directory '${path}': Permission denied`
            }

            let files = Object.entries(dir.contents)
            if (!showAll) {
                files = files.filter(([name]) => !name.startsWith('.'))
            }

            const formatSize = (size) => {
                if (!humanReadable) return size.toString().padStart(8)
                const units = ['', 'K', 'M', 'G', 'T', 'P']
                let unitIndex = 0
                while (size >= 1024 && unitIndex < units.length - 1) {
                    size /= 1024
                    unitIndex++
                }
                return `${size.toFixed(1)}${units[unitIndex]}`.padStart(5)
            }

            const formatDate = (date) => {
                const d = new Date(date)
                return d.toLocaleString('en-US', { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' })
            }

            if (longFormat) {
                const totalBlocks = files.reduce((acc, [, item]) => acc + Math.ceil((item.size || 1024) / 1024), 0)
                let output = `total ${totalBlocks}\n`
                output += files
                    .map(([name, item]) => {
                        const size = formatSize(item.size || Math.floor(Math.random() * 10000) + 1024)
                        const date = formatDate(item.modifiedDate || new Date())
                        const indicator = showIndicators && item.type === 'directory' ? '/' : ''
                        return `${item.permissions} ${item.owner.padEnd(8)} ${item.group.padEnd(
                            8
                        )} ${size} ${date} ${name}${indicator}`
                    })
                    .join('\n')
                return output
            } else {
                return files
                    .map(([name, item]) => {
                        const indicator = showIndicators && item.type === 'directory' ? '/' : ''
                        return `${name}${indicator}`
                    })
                    .join('  ')
            }
        },
        cat: (args) => {
            if (args.length === 0) return 'cat: missing file operand'
            const path = resolvePath(args[0])
            const file = getDir(path)
            if (!file) return `cat: ${args[0]}: No such file or directory`
            if (file.type !== 'file') return `cat: ${args[0]}: Is a directory`
            if (!hasPermission(file, 'r')) return `cat: ${args[0]}: Permission denied`

            // Simuler le contenu de certains fichiers système
            if (path === '/etc/passwd') {
                return (
                    'root:x:0:0:root:/root:/bin/bash\n' +
                    'daemon:x:1:1:daemon:/usr/sbin:/usr/sbin/nologin\n' +
                    'bin:x:2:2:bin:/bin:/usr/sbin/nologin\n' +
                    'sys:x:3:3:sys:/dev:/usr/sbin/nologin\n' +
                    'sync:x:4:65534:sync:/bin:/bin/sync\n' +
                    'games:x:5:60:games:/usr/games:/usr/sbin/nologin\n' +
                    'man:x:6:12:man:/var/cache/man:/usr/sbin/nologin\n' +
                    'lp:x:7:7:lp:/var/spool/lpd:/usr/sbin/nologin\n' +
                    'mail:x:8:8:mail:/var/mail:/usr/sbin/nologin\n' +
                    'news:x:9:9:news:/var/spool/news:/usr/sbin/nologin\n' +
                    'uucp:x:10:10:uucp:/var/spool/uucp:/usr/sbin/nologin\n' +
                    'proxy:x:13:13:proxy:/bin:/usr/sbin/nologin\n' +
                    'www-data:x:33:33:www-data:/var/www:/usr/sbin/nologin\n' +
                    'backup:x:34:34:backup:/var/backups:/usr/sbin/nologin\n' +
                    'list:x:38:38:Mailing List Manager:/var/list:/usr/sbin/nologin\n' +
                    'irc:x:39:39:ircd:/var/run/ircd:/usr/sbin/nologin\n' +
                    'gnats:x:41:41:Gnats Bug-Reporting System (admin):/var/lib/gnats:/usr/sbin/nologin\n' +
                    'nobody:x:65534:65534:nobody:/nonexistent:/usr/sbin/nologin\n' +
                    'systemd-network:x:100:102:systemd Network Management,,,:/run/systemd:/usr/sbin/nologin\n' +
                    'systemd-resolve:x:101:103:systemd Resolver,,,:/run/systemd:/usr/sbin/nologin\n' +
                    'systemd-timesync:x:102:104:systemd Time Synchronization,,,:/run/systemd:/usr/sbin/nologin\n' +
                    'messagebus:x:103:106::/nonexistent:/usr/sbin/nologin\n' +
                    'syslog:x:104:110::/home/syslog:/usr/sbin/nologin\n' +
                    'user:x:1000:1000:User,,,:/home/user:/bin/bash'
                )
            } else if (path === '/etc/hosts') {
                return (
                    '127.0.0.1 localhost\n' +
                    '127.0.1.1 user-desktop\n\n' +
                    '# The following lines are desirable for IPv6 capable hosts\n' +
                    '::1     ip6-localhost ip6-loopback\n' +
                    'fe00::0 ip6-localnet\n' +
                    'ff00::0 ip6-mcastprefix\n' +
                    'ff02::1 ip6-allnodes\n' +
                    'ff02::2 ip6-allrouters'
                )
            }

            return file.contents
        },
        grep: (args) => {
            if (args.length < 2) return 'grep: missing pattern and filename'
            const pattern = args[0]
            const filePath = resolvePath(args[1])
            const file = getDir(filePath)

            if (!file || file.type !== 'file') {
                return `grep: ${args[1]}: No such file or directory`
            }
            if (!hasPermission(file, 'r')) {
                return `grep: ${args[1]}: Permission denied`
            }

            const lines = file.contents.split('\n')
            const matches = lines.filter((line) => line.includes(pattern))
            return matches.length > 0 ? matches.join('\n') : 'No matches found' // Retourne les lignes correspondantes
        },

        ps: (args) => {
            const generateRandomCPU = () => (Math.random() * 5).toFixed(1)
            const generateRandomMEM = () => (Math.random() * 2).toFixed(1)

            const psOutput = [
                'USER       PID %CPU %MEM    VSZ   RSS TTY      STAT START   TIME COMMAND',
                `root         1 ${generateRandomCPU()} ${generateRandomMEM()} 225320  9128 ?        Ss   Jun22   0:04 /sbin/init`,
                `root         2 ${generateRandomCPU()} ${generateRandomMEM()}      0     0 ?        S    Jun22   0:00 [kthreadd]`,
                `root         3 ${generateRandomCPU()} ${generateRandomMEM()}      0     0 ?        I<   Jun22   0:00 [rcu_gp]`,
                `root         4 ${generateRandomCPU()} ${generateRandomMEM()}      0     0 ?        I<   Jun22   0:00 [rcu_par_gp]`,
                `root         6 ${generateRandomCPU()} ${generateRandomMEM()}      0     0 ?        I<   Jun22   0:00 [kworker/0:0H-events_highpri]`,
                `user      3264 ${generateRandomCPU()} ${generateRandomMEM()} 225872 21520 pts/0    Ss   10:12   0:00 -bash`,
                `user      3432 ${generateRandomCPU()} ${generateRandomMEM()} 228248 11776 pts/0    R+   10:15   0:00 ps aux`,
            ]

            if (args.includes('-aux') || (args.includes('-a') && args.includes('-u') && args.includes('-x'))) {
                return psOutput.join('\n')
            } else if (args.length === 0) {
                return psOutput.filter((line, index) => index === 0 || index > psOutput.length - 3).join('\n')
            } else {
                return "ps: invalid option\nTry 'ps --help' for more information."
            }
        },
        mv: (args) => {
            if (args.length !== 2) return 'mv: missing file operand'
            const sourcePath = resolvePath(args[0])
            let destPath = resolvePath(args[1])

            const sourceFile = getDir(sourcePath)
            if (!sourceFile) {
                return `mv: cannot stat '${args[0]}': No such file or directory`
            }

            const destDir = getDir(destPath)
            if (destDir && destDir.type === 'directory') {
                destPath = `${destPath}/${sourcePath.split('/').pop()}`
            }

            const newFileSystem = JSON.parse(JSON.stringify(fileSystem))
            const sourcePathParts = sourcePath.split('/').filter(Boolean)
            const destPathParts = destPath.split('/').filter(Boolean)

            // Supprimer le fichier de son emplacement d'origine
            let currentSource = newFileSystem['/']
            for (let i = 0; i < sourcePathParts.length - 1; i++) {
                currentSource = currentSource.contents[sourcePathParts[i]]
            }
            const movedFile = currentSource.contents[sourcePathParts[sourcePathParts.length - 1]]
            delete currentSource.contents[sourcePathParts[sourcePathParts.length - 1]]

            // Ajouter le fichier à sa nouvelle destination
            let currentDest = newFileSystem['/']
            for (let i = 0; i < destPathParts.length - 1; i++) {
                if (!currentDest.contents[destPathParts[i]]) {
                    currentDest.contents[destPathParts[i]] = {
                        type: 'directory',
                        permissions: 'drwxr-xr-x',
                        owner: currentUser,
                        group: currentUser,
                        contents: {},
                    }
                }
                currentDest = currentDest.contents[destPathParts[i]]
            }
            currentDest.contents[destPathParts[destPathParts.length - 1]] = movedFile

            setFileSystem(newFileSystem)
            return ''
        },
        cp: (args) => {
            if (args.length !== 2) return 'cp: missing file operand'
            const sourcePath = resolvePath(args[0])
            const destPath = resolvePath(args[1])

            const sourceFile = getDir(sourcePath)
            if (!sourceFile) return `cp: cannot stat '${args[0]}': No such file or directory`
            if (!hasPermission(sourceFile, 'r')) return `cp: cannot open '${args[0]}' for reading: Permission denied`

            let finalDestPath = destPath
            const destDir = getDir(destPath)
            if (destDir && destDir.type === 'directory') {
                finalDestPath = `${destPath}/${sourcePath.split('/').pop()}`
            }

            const destParentPath = finalDestPath.split('/').slice(0, -1).join('/')
            const destParent = getDir(destParentPath)
            if (!destParent || destParent.type !== 'directory')
                return `cp: cannot create regular file '${args[1]}': No such file or directory`
            if (!hasPermission(destParent, 'w')) return `cp: cannot create regular file '${args[1]}': Permission denied`

            // Copie récursive pour les répertoires
            const copyRecursive = (src, dest) => {
                if (src.type === 'file') {
                    return { ...src, owner: currentUser, group: currentUser }
                } else if (src.type === 'directory') {
                    const newDir = { ...src, owner: currentUser, group: currentUser, contents: {} }
                    for (const [name, content] of Object.entries(src.contents)) {
                        newDir.contents[name] = copyRecursive(content, `${dest}/${name}`)
                    }
                    return newDir
                }
            }

            updateFileSystem(finalDestPath, copyRecursive(sourceFile, finalDestPath))

            return '' // Retourne une chaîne vide en cas de succès
        },
        rm: (args) => {
            const recursive = args.includes('-r') || args.includes('-R') || args.includes('-rf')
            const force = args.includes('-f') || args.includes('-rf')
            const paths = args.filter((arg) => !arg.startsWith('-'))

            if (paths.length === 0) {
                return { output: 'rm: missing operand', success: false }
            }

            const removeRecursive = (path) => {
                const file = getDir(path)
                if (!file) {
                    if (force) return true
                    return { output: `rm: cannot remove '${path}': No such file or directory`, success: false }
                }

                if (!hasPermission(file, 'w')) {
                    if (force) return true
                    return { output: `rm: cannot remove '${path}': Permission denied`, success: false }
                }

                if (file.type === 'directory' && !recursive) {
                    return { output: `rm: cannot remove '${path}': Is a directory`, success: false }
                }

                if (file.type === 'directory') {
                    for (const [name, content] of Object.entries(file.contents)) {
                        const result = removeRecursive(`${path}/${name}`)
                        if (result !== true && !result.success) return result
                    }
                }

                const parentPath = path.split('/').slice(0, -1).join('/')
                const parent = getDir(parentPath)
                delete parent.contents[path.split('/').pop()]
                return true
            }

            for (const path of paths) {
                const result = removeRecursive(resolvePath(path))
                if (result !== true) {
                    return result
                }
            }

            return { output: '', success: true }
        },

        touch: (args) => {
            if (args.length === 0) return 'touch: missing file operand' // Vérifie si un nom de fichier a été fourni
            const path = resolvePath(args[0]) // Résoudre le chemin

            // Vérifier si le fichier existe déjà
            const file = getDir(path)
            if (file) {
                // Si le fichier existe, mettre à jour la date de dernier accès (simulé ici)
                return `Updated: ${args[0]}` // Retourne un message indiquant que le fichier a été mis à jour
            } else {
                // Créer un nouveau fichier
                const newFile = {
                    type: 'file',
                    permissions: '-rw-r--r--', // Permissions par défaut
                    owner: currentUser,
                    group: currentUser,
                    contents: '', // Le contenu du fichier est vide
                }

                // Ajoute le nouveau fichier à la structure de fichiers
                const parts = path.split('/').filter(Boolean)
                let current = fileSystem['/']

                for (let i = 0; i < parts.length - 1; i++) {
                    if (!current.contents[parts[i]] || current.contents[parts[i]].type !== 'directory') {
                        return `touch: cannot create file '${args[0]}': No such directory`
                    }
                    current = current.contents[parts[i]]
                }

                // Ajoute le fichier dans le répertoire
                current.contents[parts[parts.length - 1]] = newFile

                return `Created: ${args[0]}` // Retourne un message indiquant que le fichier a été créé
            }
        },
        echo: (args) => {
            let suppressNewline = false
            let interpretEscapes = false
            let outputText = ''
            let redirectIndex = -1
            let appendIndex = -1

            // Traiter les options et le texte
            args.forEach((arg, index) => {
                if (arg === '-n') {
                    suppressNewline = true
                } else if (arg === '-e') {
                    interpretEscapes = true
                } else if (arg === '>') {
                    redirectIndex = index
                } else if (arg === '>>') {
                    appendIndex = index
                } else if (redirectIndex === -1 && appendIndex === -1) {
                    outputText += (outputText ? ' ' : '') + arg
                }
            })

            // Interpréter les caractères d'échappement si -e est utilisé
            if (interpretEscapes) {
                outputText = outputText
                    .replace(/\\n/g, '\n')
                    .replace(/\\t/g, '\t')
                    .replace(/\\r/g, '\r')
                    .replace(/\\v/g, '\v')
                    .replace(/\\f/g, '\f')
                    .replace(/\\b/g, '\b')
            }

            // Ajouter un saut de ligne si -n n'est pas utilisé
            if (!suppressNewline) {
                outputText += '\n'
            }

            // Gérer la redirection
            if (redirectIndex !== -1 || appendIndex !== -1) {
                const operatorIndex = redirectIndex !== -1 ? redirectIndex : appendIndex
                const isAppend = appendIndex !== -1
                const filename = args[operatorIndex + 1]

                if (!filename) {
                    return { output: 'echo: missing file name', success: false }
                }

                const filePath = resolvePath(filename)
                const parts = filePath.split('/').filter(Boolean)
                let current = fileSystem['/']

                for (let i = 0; i < parts.length - 1; i++) {
                    if (!current.contents[parts[i]] || current.contents[parts[i]].type !== 'directory') {
                        return { output: `echo: cannot create file '${filename}': No such directory`, success: false }
                    }
                    current = current.contents[parts[i]]
                }

                if (current.contents[parts[parts.length - 1]]) {
                    if (isAppend) {
                        current.contents[parts[parts.length - 1]].contents += outputText
                        return { output: `Appended to file: ${filename}`, success: true }
                    } else {
                        current.contents[parts[parts.length - 1]].contents = outputText
                        return { output: `File overwritten: ${filename}`, success: true }
                    }
                } else {
                    current.contents[parts[parts.length - 1]] = {
                        type: 'file',
                        permissions: '-rw-r--r--',
                        owner: currentUser,
                        group: currentUser,
                        contents: outputText,
                    }
                    return { output: `Created file: ${filename}`, success: true }
                }
            }

            // Si pas de redirection, retourner simplement le texte
            return { output: outputText, success: true }
        },
        mkdir: (args) => {
            if (args.length === 0) return 'mkdir: missing operand'
            const path = resolvePath(args[0])

            const dir = getDir(path)
            if (dir) {
                return `mkdir: cannot create directory '${args[0]}': File exists`
            }

            const newDir = {
                type: 'directory',
                permissions: 'drwxr-xr-x',
                owner: currentUser,
                group: currentUser,
                contents: {},
                createdAt: new Date().toISOString(),
            }

            const parts = path.split('/').filter(Boolean)
            let current = fileSystem['/']

            for (let i = 0; i < parts.length - 1; i++) {
                if (!current.contents[parts[i]] || current.contents[parts[i]].type !== 'directory') {
                    return `mkdir: cannot create directory '${args[0]}': No such file or directory`
                }
                current = current.contents[parts[i]]
            }

            current.contents[parts[parts.length - 1]] = newDir
            setFileSystem({ ...fileSystem })
            return ''
        },
        ifconfig: () => {
            return `eth0: flags=4163<UP,BROADCAST,RUNNING,MULTICAST>  mtu 1500
        inet 192.168.1.100  netmask 255.255.255.0  broadcast 192.168.1.255
        inet6 fe80::215:5dff:fe00:0  prefixlen 64  scopeid 0x20<link>
        ether 00:15:5d:00:00:00  txqueuelen 1000  (Ethernet)
        RX packets 1234  bytes 1234567 (1.2 MB)
        RX errors 0  dropped 0  overruns 0  frame 0
        TX packets 5678  bytes 5678901 (5.6 MB)
        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0

wlan0: flags=4163<UP,BROADCAST,RUNNING,MULTICAST>  mtu 1500
        inet 192.168.1.101  netmask 255.255.255.0  broadcast 192.168.1.255
        inet6 fe80::1ab:2cff:fe03:4567  prefixlen 64  scopeid 0x20<link>
        ether 00:1a:2b:03:45:67  txqueuelen 1000  (Wireless)
        RX packets 9876  bytes 9876543 (9.4 MB)
        RX errors 0  dropped 0  overruns 0  frame 0
        TX packets 8765  bytes 8765432 (8.4 MB)
        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0

lo: flags=73<UP,LOOPBACK,RUNNING>  mtu 65536
        inet 127.0.0.1  netmask 255.0.0.0
        inet6 ::1  prefixlen 128  scopeid 0x10<host>
        loop  txqueuelen 1000  (Local Loopback)
        RX packets 9876  bytes 9876543 (9.8 MB)
        RX errors 0  dropped 0  overruns 0  frame 0
        TX packets 9876  bytes 9876543 (9.8 MB)
        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0`
        },

        clear: () => {
            setOutput(['Welcome to the Linux Terminal Emulator. Type "help" to display the list of commands.'])
            return { output: '', success: true }
        },
        pwd: () => {
            return currentDirectory
        },
        theme: () => {
            setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'))
            return `Theme switched to ${theme === 'dark' ? 'light' : 'dark'} mode` // Retourne une chaîne sur le changement de thème
        },
    }

    // Gestion des touches spéciales (Entrée, flèches haut/bas, tabulation)
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleCommand(input)
        } else if (e.key === 'ArrowUp') {
            e.preventDefault()
            if (historyIndex < history.length - 1) {
                setHistoryIndex((prevIndex) => prevIndex + 1)
                setInput(history[history.length - 1 - historyIndex - 1])
            }
        } else if (e.key === 'ArrowDown') {
            e.preventDefault()
            if (historyIndex > -1) {
                setHistoryIndex((prevIndex) => prevIndex - 1)
                setInput(historyIndex === 0 ? '' : history[history.length - 1 - historyIndex + 1])
            }
        } else if (e.key === 'Tab') {
            e.preventDefault()
            const [command, ...args] = input.trim().split(' ')
            const lastArg = args[args.length - 1] || ''

            // Complétion des commandes
            if (args.length === 0) {
                const matches = Object.keys(commands).filter((cmd) => cmd.startsWith(command))
                if (matches.length === 1) {
                    setInput(matches[0] + ' ')
                }
            } else {
                // Complétion des fichiers/dossiers
                const dir = getDir(currentDirectory) // Obtient le répertoire courant
                if (dir && dir.type === 'directory') {
                    const matches = Object.keys(dir.contents).filter((item) => item.startsWith(lastArg))
                    if (matches.length === 1) {
                        const newArgs = [...args.slice(0, -1), matches[0]]
                        setInput(`${command} ${newArgs.join(' ')} `)
                    } else if (matches.length > 1) {
                        console.log(matches) // Affiche les suggestions dans la console si besoin
                    }
                }
            }
        }
    }

    return (
        <div className="terminal-container">
            <div className="terminal-header">
                <div className="terminal-title">
                    <Terminal size={24} />
                    <h1>Linux Terminal Emulator</h1>
                </div>
                <button
                    className="theme-toggle"
                    onClick={() => setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'))}
                >
                    {theme === 'dark' ? <Sun size={24} /> : <Moon size={24} />}
                </button>
            </div>
            <div className="terminal-body" ref={terminalRef} style={{ height: '400px', overflowY: 'scroll' }}>
                {output.map((line, i) => {
                    if (typeof line === 'string') {
                        if (i === 0) {
                            return (
                                <div key={i} className="welcome-message">
                                    <SyntaxHighlighter
                                        language="bash"
                                        style={theme === 'dark' ? ocean : gruvboxLight}
                                        customStyle={{
                                            background: 'transparent',
                                            padding: 0,
                                            margin: 0,
                                            fontFamily: 'Fira Code',
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        {line}
                                    </SyntaxHighlighter>
                                </div>
                            )
                        } else if (line.startsWith(currentDirectory) || line.startsWith('/')) {
                            return (
                                <div key={i} className="old-prompt">
                                    {line}
                                </div>
                            )
                        } else {
                            return (
                                <div key={i} className="output">
                                    <SyntaxHighlighter
                                        language="bash"
                                        style={theme === 'dark' ? ocean : gruvboxLight}
                                        customStyle={{
                                            background: 'transparent',
                                            padding: 0,
                                            margin: 0,
                                            fontFamily: 'Fira Code',
                                        }}
                                    >
                                        {line}
                                    </SyntaxHighlighter>
                                </div>
                            )
                        }
                    }
                    return null
                })}
                <div className="terminal-input">
                    <span className="terminal-input-prompt">{currentDirectory} $</span>
                    <input
                        ref={inputRef}
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        autoFocus
                    />
                </div>
            </div>
        </div>
    )
}

export default TerminalApp
